<template>
  <v-app class="lato-font">
    <div class="row marg-0 ">
      <div class="col-md-8 col-sm-12 col-12 padd-r-40 padd-l-0 padd-t-128 padd-b-108">
        <div class="row marg-0 align-center">
          <span class="fsize24 clr-000000">Webcam verification (IPV)</span>
        </div>
        <div class="row marg-0 marg-t-30">
          <div class="col-md-6 padd-0">
            <div class="fsize12 clr-000000 marg-0">
              <div>Write the below code on a piece of paper and hold</div>
              <div class="fsize12 clr-000000 marg-0">
                it in front of the camera.
              </div>
            </div>
            <div class="marg-t-14">
              <button
                class="fsize-24 padd-0-16 bor-radius h-40 borderclr-cbcb backgroundclr-f6f6"
              >
                4918
              </button>
            </div>
            <div class="fsize12 clr-000000 marg-t-18">
              <div class="">Ensure that your face and the code are clearly</div>
              <div>visible.</div>
            </div>
          </div>
          <div class="col-md-6 padd-0">
            <div class="padd-t-20 padd-l-20 padd-b-20 borderclr-cbcb f-right bord-radius-3 f-right-none" style="width:230px;height:140px;">
             <!-- <img src="..\assets\man.svg" alt=""> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 backgroundclr-efef padd-0-30 padd-t-1-mob">
        <div class="backgroundclr-fff marg-t-44" style="height: 250px"></div>

        <div class="marg-t-14">
          <p class="fsize12 clr-000000">
            The web camera needs to be allowed access in your browser to capture
            images for the IPV. You did not allow the browser to use the web
            camera. Reload the page and try again. Please see the details here.
          </p>
        </div>
        <div class="marg-t-8 padd-b-75">
          <router-link
            to="/documents"
            active-class="active"
            tag="button"
            exact
            class="btn-color fsize18 padd-0-16 bor-radius h-40 w-100per"
          >
            Continue</router-link
          >
          <router-link
            to="/bankaccount-1"
            active-class="active"
            tag="button"
            exact
            class="borderclr-2e3031 fsize18 padd-0-16 bor-radius h-40 marg-l-8 clr-000000 w-100per marg-l-0-mob marg-t-12-mob"
          >
            Back</router-link
          >
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
.fsize-24 {
  font-size: 24px;
}
.padd-b-108 {
  padding-bottom: 108px;
}
</style>